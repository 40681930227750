@import "./theme/med-design-system.scss";
@import "@ionic/angular/css/core.css";
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/flex-utils.css";
@import "./theme/variables.scss";
@import "./theme/responsive.scss";

* {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

ion-segment {
  font-family: "Urbanist", sans-serif !important;
  --background: #f4f5f8;
}

ion-select,
ion-select-option,
ion-toast,
alert-radio-label,
alert-head,
alert-wrapper,
alert-button,
alert-title {
  font-family: "Urbanist", sans-serif !important;
  --ion-font-family: "Urbanist", sans-serif !important;
}

ion-select {
  background-color: white;
  border: 1px solid $gray-4;
  padding: 8px;
  border-radius: 10px;
}

ion-select:hover {
  box-shadow: $primary-shadow;
}

ion-segment-button {
  height: 60px;
  width: 100%;
}

.segment-title {
  width: 100%;
  padding: 3px;
  color: $primary-brand-color;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.segment-title-value {
  max-width: 120px;
  text-align: center;
  font-size: 14px;
}

.segment-page {
  // padding: 0 8px;
}

.patient-segment {
  margin-top: 16px;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-documents-wrapper input {
  border: none;
}

.sized-box {
  background-color: transparent;
  width: 100%;
  height: 100px;
}

.ios .sized-box {
  height: 150px;
}

.heading-blur-bg {
  width: 100%;
  // height: 300px;
  height: 50vh;
  background-image: url(assets/images/headingImageMobile.png);
  background-size: cover;
  background-position: center;
  position: absolute;
  z-index: -10;
  top: 0;
  right: 0;
}

.heading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.heading-overlay {
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  backdrop-filter: blur(10px);
  position: absolute;
  z-index: 2;
  right: 0;
}

.heading-actions-wrapper {
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
}

.appbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.appbar .logo {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 70px;
  width: 120px;
  background-image: $logo-url;
}

.logo svg {
  color: $primary-brand-color;
  fill: currentColor;
  transition: 300ms;
  cursor: pointer;
}

.logo svg:hover {
  color: $primary-brand-color--opacity40percent;
}

.menu-button {
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 10px;
  background-color: white;
  color: $primary-brand-color;
  display: flex;
  justify-content: center;
  align-content: center;
}

.head-info-card {
  display: flex;
  width: 100%;
  // min-width: 130px;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  background-color: white;
  color: $primary-brand-color;
  border-radius: 10px;
  box-shadow: $primary-shadow;
  transition: 300ms;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.head-info-card.isBilling {
  min-width: 160px;
}

.head-info-card > span.material-symbols-outlined {
  position: absolute;
  bottom: 3px;
  right: 5px;
  color: $blue-1;
  z-index: 2;
  font-size: 14px;
}

.head-info-card:hover {
  box-shadow: none;
  background-color: $primary-brand-color;
  color: white !important;

  .info-icon {
    background-color: $primary-brand-color;
  }
}

.info-value {
  font-size: 20px;
  font-weight: 900;
  position: relative;
  z-index: 9;
  width: 100%;
  text-align: left;
}

.info-label {
  width: 100%;
  font-size: 12px;
  text-align: right;
  letter-spacing: -0.56px;
}

.info-icon {
  width: 27.5px;
  height: 27.5px;
  border-radius: 10px;
  background-color: $gray-background;
  display: flex;
  justify-content: center;
  align-items: center;
}

.head-info-cards-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
  // overflow: auto;
}

.blockers-amount-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.blockers-amount-wrapper .material-symbols-outlined {
  color: $red-1;
}

.blockers-amount {
  font-size: 24px;
  font-weight: 700;
}

.blockers-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: flex-start;
}

// .ios .blockers-list {
//   margin-top: 40px;
// }

.blockers-title {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  align-items: center;
  box-shadow: $primary-shadow;
  background-color: white;
  padding: 4px;
  border-radius: 10px;
  width: 100%;
}

.blockers-title-row {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  border-radius: 10px;
  color: $primary-brand-color;
  width: 100%;
}

.filter-button {
  width: 50px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  box-shadow: $primary-shadow;
  padding: 4px;
  font-size: 12px;
  background-color: white;
}

.inner-bold {
  font-weight: 700;
}

// Blocker card
app-blocker-card {
  width: 100%;
  margin-bottom: 8px;
}

.blocker-card {
  padding: 8px;
  border-radius: 20px;
  box-shadow: $primary-shadow;
  // border: 2px solid $gray-background;
  display: flex;
  gap: 8px;
  width: 100%;
  background-color: white;
}

.blocker-title {
  color: $black;
  border-bottom: 2px solid rgba(255, 152, 163, 0.3019607843);
  font-weight: 700;
  font-size: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  span {
    color: $red-1;
    font-size: 12px;
    font-weight: 600;
  }
}

.blocker-description {
  color: $black;
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  line-height: 20px;
}

.horizontal-border {
  width: 100%;
  height: 1px;
  background-color: $gray-4;
}

.blocker-patient-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  width: 100%;
  background-color: $gray-background;
  padding: 2px 8px;
  border-radius: 13px;
}

.blocker-patient {
  color: $primary-brand-color;
  font-weight: 600;
}

.blocked-duration {
  color: $gray-3;
}

.blocked-patient-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 100%;
}

.blocker-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}

.resolve-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-direction: column;
  width: 200px;
  text-align: center;
}

.response-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  font-size: 14px;
  letter-spacing: -0.396px;
  height: 100%;
  width: 100%;
  padding: 4px;
  gap: 4px;
  transition: 300ms;
  cursor: pointer;
  // box-shadow: $primary-shadow;
}

.response-button:hover {
  font-weight: 900;
  box-shadow: none;
}

.response-button.isAccept {
  background-color: $green-bg;
  color: $green-1;
}

.response-button.isAccept:hover {
  background-color: $green-1;
  color: white;
}

.response-button.isReject {
  background-color: $red-bg;
  color: $red-1;
}

.response-button.isUpload {
  background-color: $blue-bg;
  color: $blue-1;
}

.response-button.isUpload:hover {
  background-color: $blue-1;
  color: $blue-bg;
}

.response-button.isReject:hover {
  background-color: $red-1;
  color: white;
}

.response-button.isAcknowledge {
  background-color: $blue-bg;
  color: $blue-1;
}

.response-button.isAcknowledge:hover {
  background-color: $blue-1;
  color: white;
}

// Patients section

.patients-list {
  padding: 8px 0px;
  border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: $primary-brand-color;
}

.patient-picture {
  width: 70px;
  height: 70px;
  min-width: 70px;
  max-width: 70px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  border: 4px solid transparent;
  position: relative;
}

.female-border {
  border-color: #ff9a9a;
}

.male-border {
  border-color: #0088d1;
}

.patient-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  transition: 300ms;
  border-radius: 5px;
  border-bottom: 1px solid $gray-4;
}

app-patient-list-item.isSelected {
  .patient-row {
    border: 1px solid $primary-brand-color;
    padding: 10px;
    box-shadow: $primary-shadow;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
  }
}

.validate-selected-patient {
  padding: 16px 8px;
  border-radius: 5px;
  background-color: $blue-bright;
  color: white;
  font-size: 18px;
  font-weight: bold;
  position: fixed;
  bottom: 20px;
  width: 90%;
  height: 90px;
  text-align: center;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transition: 500ms;
}

.validate-selected-patient .label-wrapper-validate {
  width: 80%;
}

.patient-row:hover {
  background-color: $gray-background;
}

.patient-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.patients-list li {
  width: 100%;
}

.patient-rows-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px;
  border-radius: 10px;
  background-color: white;
  overflow: auto;
}

app-patient-list-item {
  width: 100%;
}

.patient-home.hospital {
  background-color: $red-1;
  color: white;
  width: 70px;
  height: 20px;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, 0);
  font-weight: bold;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.patient-home.hospital:hover {
  width: 80px;
}

.patient-home {
  width: 130px;
  font-size: 10px;
  background-color: $blue-bg;
  color: $blue-1;
  border-radius: 20px;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: 300ms;
  font-weight: bold;
  position: relative;
}

.patient-home .account-number {
  position: absolute;
  bottom: -20px;
  color: $gray-3;
  font-weight: 300;
  font-size: 12px;
}

.patient-name .dob {
  font-size: 10px;
  color: $gray-3;
}

.patient-search-input {
  width: 90%;
}
.patient-top-filters {
  display: flex;
}
.center-icon {
  margin: auto;
  cursor: pointer;
  color: $primary-brand-color;
}

.center-icon span {
  font-size: 25px;
}

.sidebar {
  width: 100%;
  height: 97vh;
  background-color: white;
  box-shadow: $primary-shadow;
  position: sticky;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 8px;
  position: relative;
}

.sidebar-buttons-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: $primary-brand-color;
  cursor: pointer;
}

.sidebar-bg-wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  border-radius: 0 0 20px 20px;
  overflow: hidden;
  // z-index: -1;
}

.sidebar-bg {
  background-image: url(assets/images/sidebarBg.png);
  background-position: center;
  background-size: cover;
  height: 200px;
}

.sidebar-overlay {
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  height: 60px;
  position: absolute;
  top: 0;
  right: 0;
}

.logo {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: $logo-url;
}

.sidebar .logo {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $primary-brand-color;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: $logo-url;
}

.sidebar-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  background-color: white;
  border-radius: 10px;
  height: 40px;
  width: 160px;
  padding: 4px;
  padding-left: 16px;
  transition: 300ms;
  font-size: 14px;
}

.sidebar-button:hover {
  box-shadow: $primary-shadow;
  font-weight: bold;
}

.sidebar-button.isActive {
  background-color: $primary-brand-color;
  color: white;
  font-weight: bold;
}

.sidebar-button svg {
  width: 24px;
  fill: currentColor;
}

.isRxIcon {
  width: unset;
  height: 24px;
}

.user-container {
  width: 90%;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(15px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  padding: 10px;
  position: relative;
  z-index: 1;
}

.user-picture {
  width: 40px;
  height: 40px;
  background-position: center;
  background-size: contain;
  background-color: white;
  background-repeat: no-repeat;
  border-radius: 50%;
  border: 1px solid $primary-brand-color--opacity40percent;
  box-shadow: $primary-shadow;
}

.user-container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.view-profile-cta {
  color: $gray-3;
  font-size: 14px;
  transition: 300ms;
  cursor: pointer;
}

.view-profile-cta:hover {
  color: $primary-brand-color;
}

.user-info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .username {
    color: $black;
    font-size: 20px;
    // letter-spacing: -1px;
  }

  .position-name {
    color: $black;
    font-size: 14px;
  }

  .place-of-work {
    font-size: 14px;
    color: #0088d1;
  }
}

.logout {
  color: $red-1;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  cursor: pointer;
  transition: 300ms;
  background: white;
  padding: 4px;
  border-radius: 5px;
}

.logout:hover {
  font-weight: bold;
  background: $gray-background;
  padding: 4px;
  border-radius: 5px;
}
.chat {
  cursor: pointer;
}
.chat:hover {
  font-weight: bold;
  background: $gray-background;
}
// -----------------------------------

.patient-info-header {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  align-items: center;
  width: 100%;
  font-family: "Urbanist";
  background-color: $gray-background;
  border-radius: 10px;
  position: relative;
}

.patient-profile-picture {
  width: 70px;
  height: 70px;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
}

.patient-inner-info-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
}

.patient-inner-info-wrapper .patient-general-item {
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.patient-inner-info-wrapper .patient-general-item .info-label {
  width: unset;
}

.patient-inner-info-wrapper > span {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

ion-item {
  border-radius: 10px;
}

ion-item button {
  padding-left: 0 !important;
}

.item-native {
  padding: 0 !important;
}

.patient-inner-info-wrapper .insurance-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.patient-inner-info-wrapper .insurance-wrapper .insurance-value {
  color: $green-1;
  font-weight: 700;
}

.patient-inner-info-wrapper .patient-name {
  font-size: 18px;
  font-weight: 700;
}

.patient-general-items-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 8px;
  background-color: $gray-background;
  border-radius: 10px;
  gap: 8px;
}

.patient-general-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: $gray-3;
  font-size: 12px;
}

.patient-general-item .info-label {
  text-align: left;
}

.patient-general-item .info-value {
  font-size: 12px;
  font-weight: 700;
  color: $black;
  max-width: 200px;
}

.item-native {
  padding-left: 0 !important;
}

.allergies-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding: 8px;
  width: 100%;
  background-color: $gray-background;
  border-radius: 10px;
  height: 100%;
}

.allergy-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: $primary-brand-color;
  margin-bottom: 10px;
  gap: 8px;
}

.allergy-value {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
}

.patient-info-wrapper {
  background-color: white;
  // padding: 8px;
  overflow: auto;
}

.accordion-inner-patient-info-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: left;
  width: 100%;
  // height: 250px;
  margin-top: 10px;
  gap: 8px;
}

.danger-chip {
  padding: 4px;
  font-size: 12px;
  font-weight: 300;
  color: $red-1;
  background-color: $red-bg;
  margin-bottom: 4px;
  border-radius: 30px;
}

.allergies-conditions-col {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  overflow: auto;
}

.medication-list {
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  background-color: $gray-background;
  width: 100%;
}

.doctor-meds {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
  padding: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  width: 100%;
  border-bottom: 1px solid $gray-4;
}

.doctor-meds .title {
  font-size: 18px;
  color: $black;
  margin-bottom: 16px;
}

.medications-cards-wrapper-scroller {
  width: 100%;
  overflow: auto;
}

.medications-cards-wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.medication-list.ordersModal {
  padding: 16px;
}

.ordersModal .title {
  margin-top: 8px;
  margin-bottom: 16px;
}

.ordersModal .doc-name {
  font-size: 22px;
  font-weight: bold;
}

.ordersModal .medications-cards-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.medication-card {
  display: flex;
  width: 100%;
  padding: 8px;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  gap: 8px;
  border-radius: 10px;
  background-color: $gray-background;
  color: $primary-brand-color;
}

.medications-cards-wrapper .medications-card {
  width: 100%;
}

.medication-name {
  font-size: 16px;
  font-weight: 600;
  width: 250px;
}

.medication-general-info {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.medication-date,
.medication-Rx-id {
  font-size: 14px;
  // font-weight: bold;
}

.refills-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.medication-sig {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.sig-circle {
  width: 5px;
  height: 5px;
  background-color: $primary-brand-color;
  border-radius: 50%;
}

.red-text {
  color: $red-1 !important;
}

.green-text {
  color: $green-1 !important;
}

.blue-text {
  color: $blue-1 !important;
}

app-order-card {
  width: 100%;
}

.orders-head-banner {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 300;
  color: $gray-3;
  padding: 8px;
  height: 60px;
}

.order-patinetname-head {
  width: 200px;
}

.order-steps-head {
  width: 600px;
}

.order-expected-head {
  width: 70px;
}

.order-dispensedby-head {
  width: 70px;
}

.order-actions-head {
  width: 200px;
}

.order-dispensing-company {
  width: 70px;
}

.order-dispensing-company img {
  width: 100%;
}

.order-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 8px;
  border-radius: 10px;
  background-color: white;
  box-shadow: $primary-shadow;
  width: 100%;

  .order-patient {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 4px;
    border-bottom: 1px solid $gray-4;

    .patient-name {
      color: $primary-brand-color;
      font-size: 16px;
      font-weight: 700;
    }

    .patient-info-trigger {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;
      color: $gray-3;
      transition: 300ms;
      cursor: pointer;
      font-size: 12px;
    }

    .patient-info-trigger:hover {
      color: $black;
      font-size: 14px;
    }
  }

  .order-steps {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    overflow: auto;
    width: 100%;
    min-width: 100px;
    max-width: 600px;

    .order-step {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 100px;
      // background-color: $red-bg;
      // color: $red-1;
      // width: 200px;
      height: 30px;
      padding-left: 8px;
      padding-right: 8px;
      gap: 10px;
      transition: 1000ms;
    }

    .order-step.closed {
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      .step-name,
      .step-icon {
        display: none;
      }
    }

    .order-step:hover {
      width: unset;

      .step-name,
      .step-icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .order-step.isCompleted {
      background-color: $green-bg;
      color: $green-1;
      opacity: 40%;
    }

    .order-step.isBlocked {
      background-color: $red-bg;
      color: $red-1;
      opacity: 100%;
    }

    .order-step.isInProgress {
      background-color: $blue-bg;
      color: $blue-1;
      opacity: 100%;
    }

    .order-step.isCompleted .step-number {
      font-weight: 400;
    }

    .order-step.isToDo {
      background-color: $gray-4;
      color: $black;
    }

    .step-number {
      font-size: 20px;
      font-weight: 700;
      width: 20px;
      text-align: center;
    }

    .step-name {
      font-size: 14px;
      text-align: center;
      /* width: 130px; */
      /* min-width: 130px; */
      max-width: 230px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .step-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .step-icon .material-symbols-outlined {
      font-size: 19px;
    }

    .steps-divider {
      width: 10px;
      min-width: 10px;
      height: 1px;
      background-color: $gray-3;
    }
  }

  .order-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // width: 100%;
    color: $gray-3;
    font-size: 14px;
    font-weight: 300px;

    .order-dispensing-company {
      width: 38px;

      img {
        width: 100%;
      }
    }
  }

  .order-prescription-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 30%;
    color: $gray-3;
    font-size: 16px;
    background-color: white;
    border-radius: 20px;
    box-shadow: $primary-shadow;
    padding: 8px 16px;
    transition: 300ms;
    cursor: pointer;
  }

  .order-prescription-button:hover {
    color: white;
    background-color: $primary-brand-color;
    box-shadow: none;
  }
}

app-deliveries-card {
  width: 100%;
}

.delivery-cards-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  overflow: auto;
}

.delivery-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  border-radius: 10px;
  background-color: white;
  box-shadow: $primary-shadow;
  width: 100%;
  padding: 8px;

  .delivered-meds {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 8px;
    background-color: $gray-background;
    border-radius: 10px;
  }

  .delivered-med {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 4px;
    margin-bottom: 4px;
    border-bottom: 1px solid $gray-4;
  }

  .delivered-med:last-child {
    border-bottom: none;
    margin-bottom: 0px;
  }

  .inner-med-deli-info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .delivered-meds-title {
    width: 100%;
    text-align: left;
    padding-bottom: 4px;
    border-bottom: 1px solid $primary-brand-color;
    color: $primary-brand-color;
    font-weight: 700;
    margin-bottom: 8px;
  }

  .order-exp-date {
    font-size: 14px;
    font-weight: bold;
  }

  .order-exp-date .order-exp-date-label {
    font-size: 12px;
    font-weight: 400;
  }

  .delivery-card-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 8px;
    border-bottom: 1px solid $gray-4;
    opacity: 50%;

    img {
      width: 65px;
    }
  }

  .order-patient {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 4px;
    border-bottom: 1px solid $gray-4;

    .patient-name {
      color: $primary-brand-color;
      font-size: 16px;
      font-weight: 700;
    }

    .patient-info-trigger {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;
      color: $gray-3;
      transition: 300ms;
      cursor: pointer;
      font-size: 12px;
    }

    .patient-info-trigger:hover {
      color: $black;
      font-size: 14px;
    }
  }

  .order-steps {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    overflow: auto;
    width: 100%;
    min-width: 100px;
    max-width: 600px;
    border: 1px solid $gray-background;
    padding: 5px;
    border-radius: 10px;

    .order-step {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 100px;
      // background-color: $red-bg;
      // color: $red-1;
      // width: 200px;
      height: 30px;
      padding-left: 8px;
      padding-right: 8px;
      gap: 10px;
      transition: 1000ms;
    }

    .order-step.closed {
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      .step-name,
      .step-icon {
        display: none;
      }
    }

    .order-step:hover {
      width: unset;

      .step-name,
      .step-icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .order-step.isCompleted {
      background-color: $green-bg;
      color: $green-1;
      opacity: 40%;
    }

    .order-step.isBlocked {
      background-color: $red-bg;
      color: $red-1;
      opacity: 100%;
    }

    .order-step.isInProgress {
      background-color: $blue-bg;
      color: $blue-1;
      opacity: 100%;
    }

    .order-step.isCompleted .step-number {
      font-weight: 400;
    }

    .order-step.isToDo {
      background-color: $gray-4;
      color: $black;
    }

    .step-number {
      font-size: 20px;
      font-weight: 700;
      width: 20px;
      text-align: center;
    }

    .step-name {
      font-size: 14px;
      text-align: center;
      /* width: 130px; */
      /* min-width: 130px; */
      max-width: 230px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .step-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .step-icon .material-symbols-outlined {
      font-size: 19px;
    }

    .steps-divider {
      width: 10px;
      min-width: 10px;
      height: 1px;
      background-color: $gray-3;
    }
  }
}

.sign-in-header-image {
  height: 50vh;
  width: 100%;
  background-image: $login-url;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  padding: 0;
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
}

.login-slogan {
  width: 90%;
}

.desktop-slogan {
  display: flex;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  color: $primary-brand-color;
  font-size: 24px;
  width: 600px;
  margin: 0 auto;
  fill: $primary-brand-color;
  position: relative;
  z-index: 99;
}

.company-slogan-banner {
  border-radius: 10px;
  overflow: hidden;
  width: unset;
  height: 200px;
}

.company-slogan-banner img {
  opacity: 50%;
  height: 100%;
}

.login-slogan span {
  font-weight: 900;
  font-style: italic;
}

.desktop-slogan .logo {
  width: 300px;
}

.sign-in-header-image .overlay .logo {
  width: 300px;
}

.sign-in-header-image .overlay {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-size: 34px;
  font-weight: 600;
  letter-spacing: -1px;
  gap: 16px;
}

.sign-in-header-image .logo {
  background-image: $login-logo-url;
}

.login-form {
  width: 100%;
  padding: 25px 30px;
  background-color: white;
  // height: 60vh;
  border-radius: 30px 30px 0 0;
  position: absolute;
  z-index: 3;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
}

.login-form form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.login-form form input {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.login-form .input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  width: 100%;
}

.camera-prescribe-form .input-wrapper {
  width: 100%;
}

.login-form .input-wrapper label {
  color: $black;
  font-size: 18px;
  letter-spacing: -0.7px;
}

.submit-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.documents-tabs-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // gap: 8px;
  overflow: auto;
  width: 100%;
  min-height: 42px;
}

.documents-tab {
  height: 50px;
  width: unset;
  min-width: 100px;
  padding: 4px 2px;
  border: 10px;
  background-color: transparent;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: -0.36px;
  color: $primary-brand-color;
  border-radius: 10px 10px 0 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #add9ff;
  padding: 4px 8px;
  border: 1px solid $gray-background;
  svg {
    // display: none;
  }

  transition: 300ms;

  div {
    width: fit-content;
  }
}

.documents-tab.isToReview {
  color: $blue-bright;
  background-color: white;
  width: 100%;
  min-width: 150px;
  border-radius: 10px;
  margin-bottom: 8px;
}

.documents-tab:hover {
  background-color: #ffffff69;
}

.documents-tab svg {
  position: absolute;
  bottom: 0;
  right: -5px;
}

.documents-tab.isSelected {
  border: none;
  background-color: white;
  color: $primary-brand-color;
  font-weight: bold;
  box-shadow: 4px 4px 15px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 999;
  svg {
    display: block;
  }
}

.documents-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  width: 100%;
  gap: 8px;
  background-color: white;
  margin-top: -1px;
  border-radius: 0 0 10px 10px;
  height: 100%;
  overflow: auto;
  flex-wrap: wrap;
}

.documents-wrapper .head-info-card {
  box-shadow: $primary-shadow;
  color: $red-1 !important;
}

.head-info-card.isNotification,
.head-info-card.isDocsRev {
  // width: 105px !important;
  // min-width: 105px !important;
}

.document-card {
  width: 100%;
  // height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
  padding: 8px;
  border-radius: 10px;
  border: 2px solid transparent;
  background-color: white;
  box-shadow: $primary-shadow;
  cursor: pointer;
  transition: 300ms;
  border: 1px solid transparent;
}

.document-card:hover {
  box-shadow: none;
  border: 1px solid $primary-brand-color--opacity40percent;
  border-radius: 8px;
}

app-document-card {
  width: 49% !important;
  margin-bottom: 8px;
}

.flex-wrap-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

app-document-card.isSelected,
.document-card.isSelected {
  box-shadow: none;
  border: 1px solid $primary-brand-color;
  border-radius: 10px;
}

.document-title {
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: auto;
  height: 30px;
  text-overflow: ellipsis;
}

.document-blob {
  width: 100%;
  height: 100px;
  border-radius: 15px;
  background: #f0f0f3;
  box-shadow: -1.487px -1.487px 8.478px 0px rgba(0, 0, 0, 0.03) inset,
    1.487px 1.487px 8.478px 0px rgba(0, 0, 0, 0.03) inset;
  overflow: hidden;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.document-blob img {
  height: 60%;
}

.document-creator-wrapper {
  width: 100%;
}

.document-type {
  width: 22px;
}

.document-type img {
  width: 100%;
}

.document-creator-label {
  color: $gray-3;
  font-size: 12px;
}

.document-creator-name {
  color: $primary-brand-color;
  font-size: 14px;
  font-weight: 600;
}

.app-container.notificationModal {
  overflow: auto;
  .accordion-button {
    font-size: 14px;
    letter-spacing: -0.3px;
    border-bottom: 1px solid #eeeeee;
    line-height: 18px;
  }
}

.notification-card-wrapper {
  font-family: "Urbanist";
}

.accordion-item {
  border: none;
  cursor: pointer;
}

.accordion-button {
  font-family: "Urbanist";
  color: $black;
  font-size: 16px;
  padding: 8px;
  align-items: flex-start;
  transition: 300ms;
}

.accordion-button .time-ago {
  color: $gray-3;
  text-align: center;
  font-size: 12px;
  width: 30px;
  margin-right: 4px;
  width: 70px;
  min-width: 70px;
  text-align: left;
}

.notification-content {
  width: 100%;
  color: $gray-3;
  font-size: 14px;
  font-weight: 300;
  padding: 8px;
  border: 1px solid $gray-background;
  padding-bottom: 8px;
  border-bottom: 1px solid $gray-4;
  border-radius: 0 0 10px 10px;
  max-height: 400px;
  overflow: auto;
}

.documents-to-review-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
  height: 100%;
}

.documents-to-review-wrapper .overflow-vertical-wrap {
  width: 100%;
}

.documents-to-review-wrapper .document-card {
  width: 90%;
  margin: 0 auto;
}

.blocker-resolvers-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px;
  gap: 8px;
}

.resolver-button {
  background-color: $green-bg;
  color: $green-1;
  padding: 16px;
  border: 1px solid $green-1;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  border-radius: 10px;
}

.order-exp-date.delivery-number {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.upload-documents-wrapper {
  // display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  padding: 16px;
}

app-pdfviewer {
  background-color: $gray-4;
  border-radius: 20px;
  padding: 8px;
  position: relative;
}

.zoom-controls {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 20px;
  right: 10px;
  gap: 8px;
  z-index: 999;
}

.zoom-controls button {
  background-color: $primary-brand-color--opacity40percent;
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  transition: 300ms;
}

.zoom-controls button:hover {
  background-color: $primary-brand-color;
}

ion-select .select-wrapper-inner {
  width: 150px !important;
  color: $blue-bright;
}

.select-wrapper-inner > div > div {
  color: $primary-brand-color;
  font-weight: bold;
}

.submit-choice-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 24px 8px;
  font-size: 17px;
}

.submission-type-choice {
  width: 90%;
  height: 200px;
  border: 1px dashed $blue-bright;
  border-radius: 30px;
  box-shadow: $primary-shadow;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: $blue-bright;
  line-height: 100%;
  margin-bottom: 8px;
}

.choose-patient-camera-prescribe {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed $blue-bg;
  color: $blue-bright;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  height: 100px;
  border-radius: 10px;
  text-align: center;
  padding: 10px;
  box-shadow: $primary-shadow;
}

.choose-patient-camera-prescribe:hover {
  background-color: $blue-bright;
  color: white;
}

.submission-type-choice span {
  font-size: 50px;
}

.document-submission-button {
  background-color: white;
  border-radius: 20px;
  box-shadow: $primary-shadow;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  color: $blue-bright;
  font-size: 28px;
  transition: 300ms;
  border: 1px solid transparent;
  cursor: pointer;
  margin-bottom: 8px;
  padding: 24px;
}

.submission-text-wrapper {
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
}

.document-submission-button div {
  font-size: 24px;
}

.document-submission-button .submission-title {
  font-size: 24px;
}

.document-submission-button .submission-subtitle {
  font-size: 14px;
  color: $gray-3;
}

.document-submission-button svg,
.document-submission-button span {
  fill: currentColor;
  color: inherit;
  height: 45px;
  width: 45px;
  font-size: 45px;
}

.register-new-patient {
  // width: 40%;
  background-color: white;
  border-radius: 20px;
  box-shadow: $primary-shadow;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  color: $primary-brand-color;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -1px;
  transition: 300ms;
  border: 1px solid transparent;
  cursor: pointer;
  text-align: center;
  padding: 16px;
}

.register-new-patient:hover {
  border: 1px solid $primary-brand-color;
  box-shadow: none;
}

.facility-cycle-card-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: white;
  // height: 150px;
  border-radius: 10px;
  padding: 4px;
  overflow: auto;
  gap: 4px;

  .title {
    color: $primary-brand-color;
    font-size: 14px;
    letter-spacing: 2px;
    text-align: center;
    transform: rotate(-180deg);
    writing-mode: vertical-rl;
  }

  .cycle-info-section {
    display: flex;
    gap: 4px;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    border-radius: 15px;
    height: 100%;
  }

  .cycle-delivery-block {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .scheduled-delivery {
    background-color: $blue-bg;
    color: #007aff;
    padding: 4px;
    border-radius: 10px;
    width: 100%;
  }

  .scheduled-delivery {
    width: 145px;
    max-width: 145px;
    min-width: 145px;
  }

  .scheduled-delivery-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .scheduled-delivery-icon {
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
    // border: 1px solid $primary-brand-color--opacity40percent;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .scheduled-delivery-date {
    font-size: 18px;
    font-weight: 300px;

    .date-day {
      font-size: 26px;
      font-weight: 600;
    }
  }

  .scheduled-delivery-label {
    font-size: 15px;
    text-align: center;
    line-height: 89%;
    /* 14.24px */
  }

  .check-in-form-button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $blue-bright;
    padding: 8px;
    border-radius: 12px;
    box-shadow: $primary-shadow;
    height: 43px;
    font-size: 16px;
    font-weight: 700;
    transition: 300ms;
    cursor: pointer;
  }

  .check-in-form-button:hover {
    background-color: $blue-bright;
    color: white;
    box-shadow: none;
  }

  .cycle-dates-block {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 100%;
  }

  .date-box {
    width: 130px;
    height: 100%;
    background-color: $blue-bg;
    border-radius: 12px;
    color: $blue-bright;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .date-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 8px;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: -0.6px;
  }

  .date-head span {
    opacity: 50%;
  }

  .date-box-value {
    color: $blue-bright;
    font-size: 16px;
    font-weight: 300;
    // letter-spacing: -1.15px;
    text-align: center;
  }

  .date-box-value > span {
    font-size: 40px;
    font-weight: 600;
  }

  .cycle-updates-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    background: white;
    border-radius: 12px;
    padding: 8px;

    .updates-title {
      color: $gray-3;
      font-size: 14px;
      text-align: left;
      width: 100%;
    }

    .updates-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 16px;
    }

    .vertical-divider {
      width: 1px;
      height: 70%;
      background-color: $gray-4;
    }

    .update-col {
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: $primary-brand-color;
      justify-content: center;
      align-items: center;
    }

    .update-value {
      font-size: 26px;
    }

    .update-label {
      color: $gray-3;
      font-size: 10.985px;
      letter-spacing: -0.3px;
      font-weight: 700;
    }
  }

  .cycle-risk-section {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 12px;
    height: 100%;
  }

  .risk-container {
    background-color: $red-1;
    color: white;
    padding: 8px;
    border-radius: 7px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    transition: 300ms;
    position: relative;
  }

  .risk-container.isSafe {
    background-color: $green-1;
  }

  .risk-head {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    position: relative;

    .patients-affected {
      font-size: 60px;
      font-weight: 900;
    }

    .patients-affected-label {
      width: 60%;
      font-size: 16px;
      font-weight: 600;
      // letter-spacing: -0.5px;
      line-height: 110.088%;
      /* 17.614px */
    }

    .patients-affected-cta {
      position: absolute;
      right: -20px;
      bottom: -4px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      font-weight: 300;
    }
  }

  .risk-container:hover {
    color: $red-1;
    background-color: $red-bg;
  }

  .cycle-info-section {
    // width: 480px;
    // min-width: 480px;
    // max-width: 480px;
  }

  .cycle-updates-section {
    width: 240px;
    // min-width: 240px;
    // max-width: 240px;
  }

  .cycle-risk-section {
    width: 100%;
    min-width: 300px;
    max-width: 350px;
  }

  .cycle-info-section,
  .cycle-updates-section,
  .cycle-risk-section {
    box-shadow: $primary-shadow;
  }

  .cycle-risk-section {
    position: relative;
  }
}

.facility-risk-patients {
  display: flex;
  padding: 10px;
  background-color: white;
  border-radius: 20px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  transition: 500ms;
}

.isFacilityHead {
  width: 100%;
  height: 150px;
}

.powered-by {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  font-size: 11px;
  opacity: 0.5;
  margin-top: 16px;
  transition: 600ms;
}

.powered-by:hover {
  opacity: 1;
  cursor: pointer;
}

.powered-by svg {
  width: 120px;
}

.login-error-box {
  padding: 8px;
  border: 1px solid $red-1;
  color: $red-1;
  background-color: $red-bg;
  text-align: left;
  font-weight: bold;
  width: 100%;
  border-radius: 10px;
  box-shadow: $primary-shadow;
}

.isRoleSwitcherPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  gap: 16px;
  color: $primary-brand-color;
  width: 300px !important;
  margin: 0 auto;

  .logo {
    margin-bottom: 32px;
  }
}

app-head-stats-cards {
  width: 100%;
}

app-cycle-card {
  width: 100%;
}

.formio-wrapper {
  overflow: auto;
  height: 100%;
}

.modalWidth {
  --width: 95% !important;
  --border-radius: 20px;
}

.modalWidth app-patient-info {
  width: 100%;
}

.documents-to-review-wrapper .documents-wrapper {
  width: 300px;
  // position: absolute;
  z-index: 10;
  left: -275px;
  border-radius: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  // background-color: $gray-background;
  transition: 1000ms;
  overflow: visible;
}

.documents-wrapper > .material-symbols-outlined {
  color: $blue-bright;
  padding: 5px;
  border-radius: 50%;
  position: absolute;
  right: -17px;
  transform: rotate(175deg);
}

.document-review-layout > .material-symbols-outlined {
  color: $blue-bright;
  padding: 5px;
  border-radius: 50%;
  position: absolute;
  left: -17px;
  transform: rotate(175deg);
}

.document-review-title-tag {
  background-color: $gray-background;
  color: $secondary-brand-color;
  font-size: 20px;
  font-weight: bold;
  transform: rotate(90deg);
  position: absolute;
  top: 126px;
  right: -141px;
  padding: 2px 30px;
  border-radius: 10px 10px 0 0;
}

.overflow-vertical-wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  overflow: auto;
}

.sized-box-visible {
  width: 20px;
  opacity: 0;
  height: 50px !important;
  min-height: 100px;
  max-height: 100px;
}

.documents-to-review-wrapper .documents-wrapper:hover {
  left: 0;
}

.documents-to-review-wrapper .horizontal-wrapper {
  gap: 16px;
}

.document-review-layout {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background: #f4f5f8;
  padding: 16px;
  gap: 16px;
  border-radius: 10px;
  margin: 0 auto;
}

// .document-review-layout .document-review-title-tag {
//   display: none;
// }

.document-review-actions {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.document-review-actions .response-button {
  width: 100%;
}

.review-text {
  font-size: 14px;
}

.medication-list {
  background-color: white;
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust as needed */
  font-size: 1.5em;
  color: #555;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

app-tiffviewer {
  margin: auto;
  border-radius: 10px;
  overflow: auto;
  height: 100%;
}

app-tiffviewer canvas {
  height: 100%;
}

ion-modal .tiff-viewer-container {
  width: 95%;
  height: unset !important;
  margin: auto;
}

.tiff-viewer-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tiff-canvas {
  width: inherit;
  height: inherit;
  // max-height: 100%;
}

.sideBar-modal {
  --width: 200px;
  --border-radius: 30px 0 0 30px;
  --backdrop-opacity: 50%;
  right: -215px;
  transition: 300ms;
}

.title-wrap-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

ngx-skeleton-loader {
  width: 100%;
}

.doctor-meds {
  .medication-card {
    width: 100%;
  }

  .accordion-header .accordion-button {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    // width: 90%;
    padding-right: 40px;
  }

  .accordion-header {
    background-color: $gray-background;
    padding: 3px;
    border-radius: 5px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .med-header-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .medication-name {
    font-size: 16px;
    font-weight: 600;
  }

  .medication-header-name-rx {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
  }

  .accordion-header span.material-symbols-outlined {
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
    color: $primary-brand-color;
  }

  // .accordion-button[aria-expanded="true"] {
  //   background-color: $primary-brand-color;
  //   border-radius: 10px;
  //   padding: 10px;
  //   color: white !important;

  //   .medication-Rx-id {
  //     color: white;
  //   }

  //   span.material-symbols-outlined {
  //     color: white !important;
  //     transform: rotate(180deg);
  //   }
  // }

  .medication-Rx-id {
    color: $primary-brand-color;
  }

  .accordion-item {
    width: 100%;
    border: 2px solid #f4f5f8;
    border-bottom: 1px solid #b5b5b5;
  }

  .accordion-meds-content {
    width: 100%;
    padding: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    flex-direction: column;
  }

  .med-contant-wrap {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
  }

  .med-contant-wrap .med-label {
    color: $gray-3;
    width: 80px;
    min-width: 80px;
    font-size: 13px;
  }

  .med-contant-wrap .med-value {
    color: $primary-brand-color;
    width: 200px;
    max-width: 200px;
  }

  .med-image {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin: 20px 0;
  }

  .med-image img {
    width: 150px;
    border-radius: 10px;
    border: 1px solid $gray-background;
  }

  .med-hoa {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 1px solid $gray-3;
  }

  .hoa-title {
    font-weight: bold;
    font-size: 16px;
    border-top: 1px solid $gray-3;
    border-bottom: 1px solid $gray-3;
    padding: 2px;
    margin-bottom: 4px;
    width: 100%;
    text-align: center;
  }

  .refills-wrapper {
    margin: 0 auto;
    text-align: center;
    color: $primary-brand-color;
  }

  .med-directions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
  }

  .title {
    font-size: 14px;
  }

  .title .doc-name {
    font-size: 18px;
    font-weight: bold;
  }

  .medications-cards-wrapper-scroller {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0;
  }
}

.close-modal {
  color: $red-1;
}

.modal-header-bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 2px solid $gray-background;
  position: relative;
}

.modal-header-bar ion-progress-bar {
  position: absolute;
  bottom: 0;
  right: 0px;
}

.modal-bar-title {
  font-size: 18px;
  font-weight: bold;
}

.upload-prescription-form {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px;
  padding-top: 32px;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.upload-prescription-form .captured-picture,
.upload-prescription-form .chosen-patient {
  // height: 200px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0px;
}

.discard-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $red-1;
  font-size: 14px;
  text-align: center;
}

.upload-prescription-form .captured-picture {
  width: 100%;
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0px;
  border: 1px solid $gray-4;
  border-radius: 10px;
  padding: 4px;
  position: relative;

  .discard-button {
    position: absolute;
    top: -17px;
    left: 10px;
    border: 1px solid $red-1;
    background-color: white;
    color: $red-1;
    border-radius: 5px;
    padding: 4px;
  }
}

.patients-list-camera {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  padding: 8px;
  overflow: auto;
  padding-bottom: 100px;
}

.camera-prescribe-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
}

.camera-form-warpper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 12px;
  gap: 24px;
}

.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  border: 2px dashed #6c757d;
  border-radius: 8px;
  background-color: #f8f9fa;
  width: 300px;
  margin: auto;
  margin-top: 32px;
  text-align: center;
}

.file-upload-container label {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.file-upload-container label:hover {
  background-color: #0056b3;
}

.file-upload-container button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.file-upload-container button:hover:not([disabled]) {
  background-color: #218838;
}

.file-upload-container button[disabled] {
  background-color: #6c757d;
  cursor: not-allowed;
  opacity: 0.6;
}

.profile-container {
  padding: 16px;
}
.cent {
  max-height: 90vh; /* Adjust as needed */
  overflow-y: auto;
  padding: 10px;
  // border: 1px solid #ccc;
}
.center {
  justify-content: center;
  width: 100%;
  display: flex;
}

.profile-picture {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 1px solid $primary-brand-color--opacity40percent;
  position: relative;
  margin: 0 auto;
  background-position: center;
  background-size: cover;
}

.profile-picture > span {
  background: $primary-brand-color;
  border-radius: 50%;
  color: white;
  z-index: 2;
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-picture .profile-email {
  color: $gray-3;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  position: absolute;
  bottom: -20px;
  margin: 0 auto;
}

.patient-update-profile-picture {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 2px solid $primary-brand-color;
  position: relative;
  margin: 0 auto;
  background-position: center;
  background-size: cover;
}

.patient-update-profile-picture > span {
  background: $primary-brand-color;
  border-radius: 50%;
  color: white;
  z-index: 2;
  position: absolute;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toastSuccess {
  background-color: $green-bg;
  font-weight: bold;
}

.toastFailure {
  background-color: $red-bg;
  font-weight: bold;
}

app-invoice-card,
app-total-invoice-card {
  width: 100%;
  box-shadow: $primary-shadow;
}

.pay-total {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  align-items: center;
  padding: 8px;
  background-color: white;
  border-radius: 13px;
  box-shadow: $primary-shadow;
  gap: 8px;
  margin-bottom: 16px;
}

.totalpay-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pay-title {
  color: black;
  font-size: 18px;
  font-weight: bold;
}

.pay-subtitle {
  color: $gray-3;
  font-size: 14px;
}

.total-pay-value {
  width: 200px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  background-color: $red-1;
  color: white;
  font-size: 28px;
  font-weight: bold;
  padding: 8px;
}

.invoices-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.invoice-card {
  width: 100%;
  height: 70px;
  display: flex;
  padding: 4px;
  padding-left: 32px;
  position: relative;
  background-color: white;
  border-radius: 10px;
  gap: 8px;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
}

.invoice-status {
  background: $blue-bright;
  color: white;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 30px;
}

.invoice-status-label {
  position: absolute;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  font-size: 10px;
  width: 60px;
  transform: rotate(-90deg);
  margin: auto;
  right: -15px;
  bottom: 28px;
}

.invoice-inner-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px;
  width: 100%;
  justify-content: center;
  align-items: flex-start;

  .patient-name {
    font-size: 14px;
    color: $black;
    font-weight: bold;
  }

  .invoice-date-rows {
    width: 100%;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: flex-start;
  }

  .invoice-date-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 0;

    .invoice-date-label {
      font-size: 12px;
      color: $gray-3;
      width: 60px;
    }

    .invoice-date-value {
      font-size: 13px;
      color: $gray-3;
    }
  }
}

.invoice-date-value.isDeadline {
  color: $red-1 !important;
}

.invoice-pay-cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  background: $blue-bright;
  padding: 8px;
  color: white;
  width: 80px;
  position: relative;
  overflow: hidden;
}

.pay-now-label {
  text-align: center;
  font-size: 12px;
}

.invoice-pay-cta.isInvoice {
  color: $blue-bright;
  background-color: white;
  border: 1px solid $blue-bg;
  font-weight: normal;
}

.invoice-cta-wrap {
  display: flex;
  gap: 4px;
}

.isDue {
  background-color: $blue-bright !important;
}

.isPending {
  background-color: #dd7600 !important;
  opacity: 40%;
}

.isPastDue {
  background-color: red !important;
}

.isPaid {
  opacity: 50%;
  background-color: $green-1 !important;
}

.isFailed {
  background-color: $red-1 !important;
}

// .categories-filter {
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   gap: 4px;
//   width: 100%;
//   overflow: auto;

//   button {
//     width: unset;
//     padding: 4px;
//     border-radius: 5px;
//     background-color: #fff;
//     border: 1px solid $primary-brand-color--opacity40percent;
//     color: $primary-brand-color;
//     min-width: 100px;
//     overflow: auto;
//     height: 30px;
//   }
// }

.isPaymentModal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 8px;
  padding-top: 80px !important;
  padding-bottom: 40px !important;
  overflow: auto;

  .modal-header-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .invoice-item {
    width: 100%;
  }

  .invoice-card {
    box-shadow: $primary-shadow;
  }

  .total-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: $blue-bright;
    border-radius: 10px;
    box-shadow: $primary-shadow;
    padding: 8px;
    background: $blue-bright;
    color: white;

    .total-amount {
      font-size: 20px;
      font-weight: bold;
    }
  }
}

.beta-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  font-size: 10px;
  color: #888888;
  font-weight: bold;
}

.invoice-card.not-selected {
  opacity: 0.6;
}

.invoices-total-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 100%;
}

.payment-checkout-buttons-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  flex-direction: column;
}

.payment-checkout {
  width: 100%;
  background-color: #007aff;
  color: white;
  font-weight: bold;
  font-size: 22px;
  padding: 8px;
  border-radius: 7px;
  height: 80px;
  margin-top: 16px;
}

.payment-methods-wrapper {
  width: 100%;

  ion-segment {
    width: 100%;
  }
}

.payment-methods-wrapper {
  // margin-top: 8px;
}

.total-section {
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.segment-title {
  display: flex;
  align-items: center;
  gap: 8px;
}

.segment-page {
  margin-top: 8px;
}

.wallet-method,
.credit-card-method,
.ach-method {
  padding: 8px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: $primary-shadow;
  max-width: 400px;
  margin: 0 auto;
}

.isPaymentModal {
  h3 {
    font-size: 20px;
    color: $primary-brand-color;
    margin-bottom: 4px;
    margin-top: 4px;
    font-weight: bold;
  }
  form {
    label {
      font-size: 12px;
      color: $black;
      margin-top: 8px;
    }
  }

  .payment-layout-fixer-item {
    margin-top: 4px;
  }
}

.wallet-method {
  padding: 8px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: $primary-shadow;
  max-width: 400px;
  margin: 0 auto;

  p {
    color: #666;
    margin-bottom: 1.5rem;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  div {
    position: relative;

    input[type="radio"] {
      display: none;
    }

    label {
      display: flex;
      align-items: center;
      padding: 16px;
      background-color: #f8f9fa;
      border: 2px solid #e9ecef;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.2s ease;
      width: 100%;
      margin-bottom: 12px;

      &:before {
        content: "";
        width: 20px;
        height: 20px;
        border: 2px solid #cbd5e0;
        border-radius: 50%;
        margin-right: 12px;
        transition: all 0.2s ease;
      }

      .card-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        width: 100%;

        .card-type {
          font-weight: 600;
          color: #2d3748;
        }

        .card-number {
          color: #718096;
          font-size: 16px;
        }
      }
    }

    input[type="radio"]:checked + label {
      border-color: #3182ce;
      background-color: #ebf8ff;

      &:before {
        border-color: #3182ce;
        background-color: #3182ce;
        box-shadow: inset 0 0 0 4px #fff;
      }
    }

    &:hover label {
      border-color: #cbd5e0;
      background-color: #f1f5f9;
    }
  }
}

.add-card-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  font-weight: bold;
  color: $primary-brand-color;
  border: 1px solid $primary-brand-color--opacity40percent;
  border-radius: 5px;
  padding: 8px;
  margin-top: 8px;
  cursor: pointer;
  background-color: white;
}

.isPaymentModal {
  input {
    padding: 8px;
  }
}

// form label {
//   display: block;
//   margin-bottom: 5px;
//   font-weight: bold;
// }

// form input[type="text"],
// form input[type="checkbox"] {
//   width: 100%;
//   padding: 8px;
//   margin-bottom: 10px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
// }

// form input[type="checkbox"] {
//   width: auto;
// }

.payment-checkout-buttons-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.payment-checkout {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.payment-checkout:hover {
  background-color: #0056b3;
}

.payment-layout-fixer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  .payment-layout-fixer-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
}

.account-holder-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;

  div {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  input {
    width: 20px;
  }
}
.credit-card-method,
.ach-method {
  background-color: white;
  padding: 1rem;
  border-radius: 4px;
  color: #fff;

  label {
    display: block;
    // margin-top: 1rem;
    font-weight: 600;
  }

  .bt-card-element {
    margin-top: 0.5rem;
    padding: 0.5rem;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

  .error-message {
    color: #ff6b6b;
    margin-top: 1rem;
  }

  // button {
  //   margin-top: 1.5rem;
  //   padding: 0.75rem;
  //   width: 100%;
  //   background-color: #3256a2;
  //   border: none;
  //   border-radius: 4px;
  //   color: white;
  //   font-size: 1rem;
  //   font-weight: 600;
  //   cursor: pointer;
  // }
}

.account-holder-type.bt-card-element {
  label,
  input {
    margin: 0;
  }
}

.save-card-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  margin-top: 8px;

  input {
    width: 20px;
  }
}
